import { IsValidProp } from '../helper.service'

const controllerName = 'users'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function RegisterAdmin(formModel) {
  return window.axios.post('api/users', formModel)
}

function PutAdmin(id, formModel) {
  return window.axios.put(`api/users/${id}`, formModel)
}

function PutCustomer(id, formModel) {
  return window.axios.put(`api/users/customer/${id}`, formModel)
}

function PutCustomerOwnAccount(id, formModel) {
  return window.axios.put(`api/users/customer/${id}`, formModel)
}

function PutCustomerOwnPassword(id, formModel) {
  return window.axios.put(`api/users/customer/${id}/password`, formModel)
}

function ChangePasswordAdmin(formModel) {
  return window.axios.post('api/users/change-password', formModel)
}

function RegisterCustomer(formModel) {
  return window.axios.post('api/users/customer', formModel)
}

function DeleteUser(id, isHardDelete) {
  return window.axios.delete(`api/users/${id}/${isHardDelete}`)
}

function CustomerForgotPassword(formModel) {
  return window.axios.post('api/users/forgot-password', formModel)
}

function ResetPassword(formModel) {
  return window.axios.post('api/users/reset-password', formModel)
}

function ActivateAccount(formModel) {
  return window.axios.post('api/users/activate-account', formModel)
}

function GetAllAgents() {
  return window.axios.get(`api/${controllerName}/get/agents`)
}

function GetAgentById(id) {
  return window.axios.get(`api/${controllerName}/get/agent/${id}`)
}

function GetUserPhoto(id) {
  return window.axios.get(`api/${controllerName}/photo/get/${id}`)
}

function PutUserPhoto(id, formModel) {
  const formData = new FormData()
  for (const key in formModel.model) {
    const mainObj = formModel.model[key]
    if (key === 'reportFile') {
      formData.append(
        key,
        mainObj == null ? null : mainObj
      )
    } else if (key === 'imageFile') {
      if (IsValidProp(mainObj)) {
        formData.append(key, mainObj.file)
      }
    } else if (IsValidProp(mainObj)) {
      formData.append(
        key,
        mainObj == null ? '' : mainObj
      )
    }
  }
  
  return window.axios.put(`api/${controllerName}/photo/upload/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export {
  Get,
  RegisterAdmin,
  PutAdmin,
  PutCustomer,
  PutCustomerOwnAccount,
  PutCustomerOwnPassword,
  RegisterCustomer,
  ChangePasswordAdmin,
  DeleteUser,
  CustomerForgotPassword,
  ResetPassword,
  ActivateAccount,
  GetAllAgents,
  GetAgentById,
  GetUserPhoto,
  PutUserPhoto
}
