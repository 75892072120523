export const SET_STATUSES = 'SetPropertyStatuses'
export const SET_TYPES = 'SetPropertyTypes'
export const SET_AGENT_FILTER = 'SetAgentFilter'
export const SET_PER_PAGE = 'SetPerPage'
export const SET_SORT_BY = 'SetSortBy'
export const SET_SORT_DIR = 'SetSortDir'
export const SET_CURRENT_PAGE = 'SetCurrentPage'

const state = {
  propertyStatuses: [],
  propertyTypes: [],
  agentFilter: null,
  perPage: 20,
  sortBy: 'CreatedAt',
  sortDir: 'asc',
  currentPage: 1,
}

const getters = {
  propertyStatuses: state => state.propertyStatuses,
  propertyTypes: state => state.propertyTypes,
  agentFilter: state => state.agentFilter,
  perPage: state => state.perPage,
  sortBy: state => state.sortBy,
  sortDir: state => state.sortDir,
  currentPage: state => state.currentPage
}

const mutations = {
  [SET_STATUSES](state, payload) {
    state.propertyStatuses = payload
  },
  [SET_TYPES](state, payload) {
    state.propertyTypes = payload
  },
  [SET_AGENT_FILTER](state, payload) {
    state.agentFilter = payload
  },
  [SET_PER_PAGE](state, payload) {
    state.perPage = payload
  },
  [SET_SORT_BY](state, payload) {
    state.sortBy = payload
  },
  [SET_SORT_DIR](state, payload) {
    state.sortDir = payload
  },
  [SET_CURRENT_PAGE](state, payload) {
    state.currentPage = payload
  }
}

const actions = {
  [SET_STATUSES]({ commit }, payload) {
    commit(SET_STATUSES, payload)
  },
  [SET_TYPES]({ commit }, payload) {
    commit(SET_TYPES, payload)
  },
  [SET_AGENT_FILTER]({ commit }, payload) {
    commit(SET_AGENT_FILTER, payload)
  },
  [SET_PER_PAGE]({ commit }, payload) {
    commit(SET_PER_PAGE, payload)
  },
  [SET_SORT_BY]({ commit }, payload) {
    commit(SET_SORT_BY, payload)
  },
  [SET_SORT_DIR]({ commit }, payload) {
    commit(SET_SORT_DIR, payload)
  },
  [SET_CURRENT_PAGE]({ commit }, payload) {
    commit(SET_CURRENT_PAGE, payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
