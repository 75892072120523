const controllerName = 'property-statuses'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetAll(params) {
  return window.axios.get(`api/${controllerName}`, { params })
}

function Post(formModel) {
  return window.axios.post(`api/${controllerName}`, formModel)
}

function Put(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/key-value`)
}

function GetAllPropStatusWithListingAggregates() {
  return window.axios.get(`api/${controllerName}/get/listingaggregates`)
}

function GetAllPropertyStatusesForPublic(params) {
  return window.axios.get(`api/${controllerName}/get/allforpublic`, { params })
}

export { Get, GetAll, Post, Put, GetAllPropStatusWithListingAggregates, KeyValue, GetAllPropertyStatusesForPublic }
