import Role from '@/core/constant/role'
import { KeyValue as GetCertificates } from '@/core/services/entities/certificates.service'
import { KeyValue as GetAreas } from '@/core/services/entities/areas.service'
import { KeyValue as GetPropertyStatuses } from '@/core/services/entities/property-statuses.service'
import { KeyValue as GetPropertyTypes } from '@/core/services/entities/property-types.service'

export const REFRESH_ALL = 'RefreshAllMaster'
export const REFRESH_CERTIFICATES = 'RefreshCertificates'
export const REFRESH_PROPERTY_STATUSES = 'RefreshPropertyStatuses'
export const REFRESH_PROPERTY_TYPES = 'RefreshPropertyTypes'
export const REFRESH_AREAS = 'RefreshAreas'

const state = {
  certificates: [],
  propertyStatuses: [],
  propertyTypes: [],
  areas: []
}

const getters = {
  roles() {
    return [
      { id: Role.SystemAdmin, name: 'System Admin' },
      { id: Role.CustomerAdmin, name: 'Customer Admin' },
      { id: Role.SuperAdmin, name: 'Super Admin' }
    ]
  },
  certificates(state) {
    return state.certificates
  },
  propertyStatuses(state) {
    return state.propertyStatuses
  },
  propertyTypes(state) {
    return state.propertyTypes
  },
  areas(state) {
    return state.areas
  }
}

const mutations = {
  [REFRESH_CERTIFICATES](state, data) {
    state.certificates = data
  },
  [REFRESH_PROPERTY_STATUSES](state, data) {
    state.propertyStatuses = data
  },
  [REFRESH_PROPERTY_TYPES](state, data) {
    state.propertyTypes = data
  },
  [REFRESH_AREAS](state, data) {
    state.areas = data
  }
}

const actions = {
  async [REFRESH_CERTIFICATES]({ commit }) {
    const response = await GetCertificates()
    if (response.status === 200) {
      commit(REFRESH_CERTIFICATES, response.data.data)
    }
  },
  async [REFRESH_AREAS]({ commit }) {
    const response = await GetAreas()
    if (response.status === 200) {
      commit(REFRESH_AREAS, response.data.data)
    }
  },
  async [REFRESH_PROPERTY_STATUSES]({ commit }) {
    const response = await GetPropertyStatuses()
    if (response.status === 200) {
      commit(REFRESH_PROPERTY_STATUSES, response.data.data)
    }
  },
  async [REFRESH_PROPERTY_TYPES]({ commit }) {
    const response = await GetPropertyTypes()
    if (response.status === 200) {
      commit(REFRESH_PROPERTY_TYPES, response.data.data)
    }
  },
  [REFRESH_ALL]({ dispatch }) {
    Promise.allSettled([
      dispatch(REFRESH_CERTIFICATES),
      dispatch(REFRESH_PROPERTY_STATUSES),
      dispatch(REFRESH_PROPERTY_TYPES),
      dispatch(REFRESH_AREAS),
    ])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
