const state = () => {
  return {
    menu: [
      {
        icon: 'UserIcon',
        pageName: 'admins',
        title: 'Users'
      },
      {
        icon: 'UserIcon',
        pageName: 'adminPropertyTypes',
        title: 'Property Types'
      },
      {
        icon: 'UserIcon',
        pageName: 'adminPropertyStatuses',
        title: 'Property Statuses'
      },
      {
        icon: 'UserIcon',
        pageName: 'adminAreas',
        title: 'Areas'
      },
      {
        icon: 'UserIcon',
        pageName: 'adminCertificates',
        title: 'Certificates'
      },
      { icon: 'UserIcon', pageName: 'adminListings', title: 'Listings' },
      { icon: 'UserIcon', pageName: 'adminContactSubmissions', title: 'Contact Submissions'}
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// {
//   icon: 'HomeIcon',
//   pageName: 'side-menu-dashboard',
//   title: 'Dashboard',
//   subMenu: [
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-1',
//       title: 'Overview 1'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-2',
//       title: 'Overview 2'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-3',
//       title: 'Overview 3'
//     }
//   ]
// },
