import { createRouter, createWebHistory } from 'vue-router'
import BaseParent from '@/layouts/BaseParent.vue'
import Login from '../views/login/Main.vue'

const routes = [
  {
    path: '/admin',
    component: () => import('@/layouts/side-menu/Main.vue'),
    children: [
      {
        path: '',
        name: 'adminDashboard',
        component: () => import('@/views/dashboard/Main.vue')
      },
      {
        path: 'admins',
        name: 'baseAdmins',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'admins',
            component: () => import('@/views/.admin/Admins/Index.vue')
          }
        ]
      },
      {
        path: 'areas',
        name: 'baseAreas',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminAreas',
            component: () => import('@/views/.admin/Areas/Index.vue')
          }
        ]
      },
      {
        path: 'propertyTypes',
        name: 'basePropertyTypes',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminPropertyTypes',
            component: () => import('@/views/.admin/PropertyTypes/Index.vue')
          },
          {
            path: 'view/:id',
            name: 'adminViewPropertyType',
            props: true,
            component: () => import('@/views/.admin/PropertyTypes/View.vue')
          }
        ]
      },
      {
        path: 'propertyStatuses',
        name: 'basePropertyStatuses',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminPropertyStatuses',
            component: () => import('@/views/.admin/PropertyStatuses/Index.vue')
          },
          {
            path: 'view/:id',
            name: 'adminViewPropertyStatus',
            props: true,
            component: () => import('@/views/.admin/PropertyStatuses/View.vue')
          }
        ]
      },
      {
        path: 'listings',
        name: 'baseListings',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminListings',
            component: () => import('@/views/.admin/Listings/Index.vue')
          },
          {
            path: 'view/:id',
            name: 'adminViewListing',
            props: true,
            component: () => import('@/views/.admin/Listings/View.vue')
          }
        ]
      },
      {
        path: 'certificates',
        name: 'baseCertificates',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminCertificates',
            component: () => import('@/views/.admin/Certificates/Index.vue')
          },
          {
            path: 'view/:id',
            name: 'adminViewCertificate',
            props: true,
            component: () => import('@/views/.admin/Certificates/View.vue')
          }
        ]
      },
      {
        path: 'contactSubmissions',
        name: 'baseContactSubmissions',
        component: BaseParent,
        children: [
          {
            path: '',
            name: 'adminContactSubmissions',
            component: () => import('@/views/.admin/ContactSubmissions/Index.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/frontend/FrontendLayout.vue'),
    children: [
      {
        path: '',
        name: 'frontendHome',
        component: () => import('@/views/.frontend/Home.vue')
      },
      {
        path: 'team',
        name: 'frontendTeam',
        component: () => import('@/views/.frontend/Team.vue')
      },
      {
        path: 'team-detail/:id',
        name: 'frontendTeamDetail',
        props: true,
        component: () => import('@/views/.frontend/TeamDetail.vue')
      },
      {
        path: 'property',
        name: 'frontendProperty',
        component: () => import('@/views/.frontend/Property.vue')
      },
      {
        path: 'property/detail/:id',
        name: 'propertyDetail',
        component: () => import('@/views/.frontend/PropertyDetail.vue')
      },
      {
        path: 'contact',
        name: 'frontendContact',
        component: () => import('@/views/.frontend/Contact.vue')
      }
    ]
  },
  {
    name: 'adminLogin',
    path: '/admin/login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
