const controllerName = 'property-types'

function Get(id) {
  return window.axios.get(`api/${controllerName}/${id}`)
}

function GetAll(params) {
  return window.axios.get(`api/${controllerName}`, { params })
}

function Post(formModel) {
  return window.axios.post(`api/${controllerName}`, formModel)
}

function Put(id, formModel) {
  return window.axios.put(`api/${controllerName}/${id}`, formModel)
}

function GetAllPropTypeWithListingAggregates() {
  return window.axios.get(`api/${controllerName}/get/listingaggregates`)
}

function KeyValue() {
  return window.axios.get(`api/${controllerName}/get/key-value`)
}

function GetAllPropertyTypesForPublic(params) {
  return window.axios.get(`api/${controllerName}/get/allforpublic`, { params })
}

export { Get, GetAll, Post, Put, GetAllPropTypeWithListingAggregates, KeyValue, GetAllPropertyTypesForPublic }
