<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Ray White Riau` : `Ray White Riau`
    }}</template>
  </metainfo>
  <router-view />
</template>

<script>
</script>

<style>
</style>
