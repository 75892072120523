<!-- fieldAwesome.vue -->
<template>
  <SimpleTypeahead
    ref="typeahead"
    class="form-control"
    placeholder="Start writing..."
    :items="items"
    :defaultItem="defaultItem"
    :minInputLength="3"
    :itemProjection="
      item => {
        return item.value
      }
    "
    @selectItem="selectItem"
    @onInput="onInput"
  >
  </SimpleTypeahead>
</template>

<script>
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css' //Optional default CSS
import { abstractField } from 'vfg-next'

export default {
  data() {
    return {
      items: [],
      defaultItem: null
    }
  },
  methods: {
    selectItem(item) {
      this.value = item.key
    },
    async onInput(event) {
      if (event.input.length >= 3) {
        const response = await this.schema.fetch(event.input)
        this.items = response.data.data
      }
    }
  },
  mounted() {
    if (this.schema.defaultValue) {
      this.defaultItem = this.schema.defaultValue
      this.items = [this.schema.defaultValue]
      this.$refs.typeahead.input = this.schema.defaultValue.value
    }
  },
  components: {
    SimpleTypeahead: SimpleTypeahead
  },
  mixins: [abstractField]
}
</script>
